<template>
<select aria-label="supported languages" class="lang-dropdown"  @change="emitLocaleChangeEvent($event)">
    <!-- <select aria-label="supported languages" class="lang-dropdown" v-model="selectedLocale" @change="emitLocaleChangeEvent($event)"> -->
    <!--<select aria-label="supported languages" class="lang-dropdown" v-model="$i18n.locale" @change="$emit('localeChange',$event.target.value)">-->
    <option :selected="isSelected(locale.code, selectedLocale)" :value="locale.code" v-for="locale in locales" :key="locale.code">
        {{ locale.name }}
    </option>
</select>
</template>

<script>
export default {
    data: () => ({
       // selected: this.selectedLocale
    }),
    props: {
        locales: {
            type: Array,
            required: true
        },
        selectedLocale: {
            type: String,
            required: false,
            default: "en"
        },
    },
    methods: {
        isSelected(localeCode, selectedLocale){
            return localeCode === selectedLocale
        },
        emitLocaleChangeEvent(event) {
           this.$emit('localeChange', event.target.value);
       // this.selected = event.target.value;
            /*
            const jsEvent = new CustomEvent('localeChange', {
                detail: {
                    locale: event.target.value,
                    context: this
                }
            });
            document.dispatchEvent(jsEvent);
            */
        }
    },
};
</script>

<style lang="scss">
.lang-dropdown {
    border: 1px solid #dedede;
    border-radius: 5px;
    background: #ffffff;
    margin: 0;
    padding: 10px 3.5em 10px 1em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, #909090 50%),
        linear-gradient(135deg, #8f8f8f 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    //width: 200px;
    min-width: 150px;

    /* IE11 hide hacks*/
    &::-ms-expand {
        display: none;
    }
}
</style>