<template>
<div id="ui-login-wrapper">
    <header class="lang-dropdown-wrapper">
        <lang-dropdown :locales="locales" :selectedLocale="selectedOption" class="login-lang-dropdown" @localeChange="handleLocaleChangeEvent"></lang-dropdown>
    </header>
    <main id="js-login" class="login">
        <div class="login__logo-wrapper" :class="{ 'login__logo-wrapper--bg': !isDefaultStyle }">
            <img v-if="isDefaultStyle" class="login__logo" :src="require(`@/assets/images/${logoSrc}`)" alt="" />
        </div>
        <div class="login__form-wrapper">
            <div class="login__form">
                <img v-if="!isDefaultStyle" class="login__form-logo" :src="require(`@/assets/images/${logoSrc}`)" alt="" />
                <h1 class="login__form-heading" v-html="$t('login_welcome_title')"></h1>
                <p class="login__form-text" v-html="$t('login_welcome_text_html')"></p>
                <form class="login-form" v-on:submit.prevent="login">
                    <label :aria-label="$t('login_password')" class="login__password-input-label">
                        <input class="login__password-input" :class="{ 'login__password-input--error': error }" :placeholder="$t('login_password')" type="password" name="password" v-model.trim="password" @input="removeErrorAlert" />
                    </label>
                    <div role="alert" class="d-login-alert-error" v-if="error">
                        <i class="login-error-icon fas fa-exclamation-circle"></i>
                        {{ $t("password_error_message") }}
                    </div>
                    <ui-button type="button" class="login-btn" @click="login()">
                        {{ $t("login_button") }}
                    </ui-button>
                </form>
            </div>
            <copyright-footer class="login-copyright" :locale="$i18n.locale"></copyright-footer>
        </div>
    </main>
    <aside>
        <cookie-modal v-on:consentAccepted="acceptConsent"></cookie-modal>
    </aside>
</div>
</template>

<script>
import CookieModal from "@/components/CookieModal.vue";
import utils, {setPageTitle} from "@/common/utils";
import consent from "@/common/consent";
import {processLogin} from "@/plugins/auth";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import UiButtonVue from "@/components/UiButton.vue";
import CopyRightLabel from "@/components/CopyRightLabel.vue";
import {
    isDefaultStyle,
    logoPath,
    defaultLocale
} from "@/config";
import {
    getpreferredLocales, 
    setCurrentLocale, 
    isLocaleInLocalesFolder,
    isLocaleSupported
} from "@/plugins/i18n";

export default {
    components: {
        "lang-dropdown": LocaleSwitcher,
        "ui-button": UiButtonVue,
        "copyright-footer": CopyRightLabel,
        "cookie-modal": CookieModal,
    },
    data() {
        return {
            isDefaultStyle: isDefaultStyle,
            error: null,
            password: null,
            locales: getpreferredLocales(),
            logoSrc: logoPath,
            selectedOption: defaultLocale
        };
    },
    created(){
        this.handleUrl()
    },
    mounted() {
        utils.resizeOverlay("js-login");
        setPageTitle(this.$t('site_title'))
    },
    methods: {
        login() {
            processLogin(this, this.password);
        },
        removeErrorAlert() {
            this.error = false;
        },

        handleUrl(){
            let currentLocale = this.$i18n.locale

             if (currentLocale !== undefined){
                   // eg if a user types the url: http://localhost:8081/login?lang=es but the es locale is not in the language dropdwon 
                  if (isLocaleInLocalesFolder(currentLocale) && !isLocaleSupported(currentLocale)){
                      this.selectedOption = defaultLocale
                  }else{
                      this.selectedOption = currentLocale
                  }
             }

        },
        

        acceptConsent() {
            consent.setStatus(true);
          //  console.log("Login::consent status is: " + consent.status)
            utils.wistiaCookieTracking(true);
        },
         handleLocaleChangeEvent(locale){
            this.selectedOption = locale
            setCurrentLocale(locale)
            utils.handleLocaleChangeEvent(this,locale);
            setPageTitle(this.$t('site_title'))
        }
    },
};
</script>

<style lang="scss">
@import "../assets/styles/login.scss";
</style>
